<template>

    <span>
 
    
     <b-badge v-if="loading" variant="danger"> <img style="width:10px " src="/img/loading2.svg" alt="Loading" />  </b-badge>
     <b-badge v-else variant="danger"> {{ count }} </b-badge>
    </span>
       
       </template>
       
       <script>
       import {
         BButton, BRow, VBPopover, BCol, BCard, BBadge
       } from 'bootstrap-vue'
       
       
       
       export default {
         components: {
          
           BBadge,
           BButton,
           BRow,
           BCol,
           BCard,
   
         },
         directives: {
           'b-popover': VBPopover,
         },
         props: ['userId', 'tokenAuth','idAdmin'],
         data() {
       
       
           return {
       
               loading:true,
               count:0,
           }
         },
         computed: {
       
         },
         watch: {
       
         },
         created(){
 
          
 
         },
         mounted() {
          this.loadCounter();
         },
         methods: {
 
             loadCounter(){
                 this.loading=true;

                 const data_json = { userId: this.userId, idAdmin: this.idAdmin }

                 const data_string = JSON.stringify(data_json)
    
        
                     const dataEncripted = this.$encryptAdmin.encrypt(data_string);
 
                 this.$https.post('/administracion/countPaypal/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
 
                                         
                     if (response.data.code == 200) {
 
                         this.loading = false;
 
 
                         this.count =  response.data.count;
  
 
                     } else {
 
 
                     if (response.data.code == 401) {
 
                         this.$toast.error(response.data.message, {
                         position: 'top-right',
                         timeout: 3010,
                         closeOnClick: true,
                         pauseOnFocusLoss: false,
                         pauseOnHover: true,
                         draggable: true,
                         draggablePercent: 0.4,
                         showCloseButtonOnHover: false,
                         hideProgressBar: true,
                         closeButton: 'button',
                         icon: true,
                         rtl: false,
                         })
                         
                         localStorage.removeItem('userData')
 
 
                         this.$router.push({ name: 'auth-login' })
                     } else {
 
                         this.loadCounter();
                         
                     }
                     }
                     }).catch(error => {
                     this.loadCounter();
                     })
             }
   
       
          
       
         },
       }
       </script>
       
       <style lang="scss">
       
       
       
       </style>
       